import SiteNavigation from './SiteNavigation'
import ProfileDropdown from './ProfileDropdown'
import Logo from '~/core/ui/Logo'
import Container from '~/core/ui/Container'
import If from '~/core/ui/If'
import Button from '~/core/ui/Button'
import DarkModeToggle from '~/components/DarkModeToggle'
import useSignOut from '~/core/hooks/use-sign-out'
import useUserSession from '~/core/hooks/use-user-session'

import configuration from '~/configuration'
import ClientOnly from '~/core/ui/ClientOnly'

function SiteHeader() {
  const signOut = useSignOut()
  const userSession = useUserSession()

  return (
    <Container>
      <div className="dark:border-dark-800/70 flex items-center justify-between py-1.5">
        <div className="w-4/12">
          <Logo />
        </div>

        <div className="hidden w-4/12 justify-center lg:flex">
          <SiteNavigation />
        </div>

        <div className="flex flex-1 items-center justify-end space-x-4">
          <div className="flex items-center">
            <ClientOnly>
              <If condition={configuration.features.enableThemeSwitcher}>
                <DarkModeToggle />
              </If>
            </ClientOnly>
          </div>

          <If condition={userSession?.auth} fallback={<AuthButtons />}>
            <ProfileDropdown
              displayName={false}
              userSession={userSession}
              signOutRequested={signOut}
            />
          </If>

          <div className="flex lg:hidden">
            <SiteNavigation />
          </div>
        </div>
      </div>
    </Container>
  )
}

function AuthButtons() {
  return (
    <div className="hidden space-x-2 lg:flex">
      <Button round variant="ghost" href={configuration.paths.signIn}>
        <span>Sign In</span>
      </Button>

      {/* <Button round href={configuration.paths.signUp}>
        <span className={'flex items-center space-x-2'}>
          <span>Sign Up</span>
          <ChevronRightIcon className={'h-4'} />
        </span>
      </Button> */}
    </div>
  )
}

export default SiteHeader
